:root {
  //overflow-y: hidden;
    --color-primary: hsl(0, 82%, 47%);
    --color-secondary: hsl(160, 100%, 65%);
    --background: hsl(230, 30%, 15%);
    --text: hsl(310, 100%, 95%);
  }
  
  ::selection {
    background-color: var(--color-primary);
    color: var(--background);
    -webkit-text-fill-color: var(--background);
  }
  
  * {
    box-sizing: border-box;
  }
    
  body {
    background-color: var(--background);
    color: var(--text);
  }
  
  h1 {
    color: var(--color-primary);
  }
  
  blockquote {
    border-left: 5px solid;
    margin-left: 0;
    padding: 1rem 0 1rem 2rem;
    font-size: 2rem;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
      35deg,
      var(--color-primary),
      var(--color-secondary)
    );
  
    p {
      margin: 0;
    }
  }
  