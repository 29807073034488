.flyer {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 10px solid transparent;
  /* padding-top: 15px; */
}

.flyer img{
  margin-top: 10px;

}

@media screen and (max-width: 480px) {
  .flyer img {
       width: 400px;
  }
}


@media screen and (max-width: 780px) and (min-width: 480px) {
  .flyer img {
       width: 700px;
  }
}