.container{
display: flex;
flex-wrap: wrap;

}

.profile-container{
 padding: 15px!important;
 
}

.name{
text-align: center;

}

.image{
    height: 100px;
    width: 100px;
    padding: 10px;
}

.picture-container{
    width: 300px;
    height: 300px;
    text-align: center;
}




section {
    padding: 60px 0;
    min-height: 100vh;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.icon{
    margin: auto;
    max-height:100%; max-width:100%;
}

.site-heading h2 {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  /* display: block; */
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.site-heading h2 span {
  color: #820505;
}

.site-heading h4 {
  display: inline-block;
  padding-bottom: 20px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
}

.site-heading {
  margin-bottom: 60px;
  overflow: hidden;
  margin-top: -5px;
}

.carousel-shadow .owl-stage-outer {
  margin: -15px -15px 0;
  padding: 15px;
}

.our-offer-items {
  margin-top: 20px;
}

.we-offer-area .our-offer-carousel .owl-dots .owl-dot span {
  background: #252424 none repeat scroll 0 0;
  border: 2px solid;
  height: 15px;
  margin: 0 5px;
  width: 15px;
}




.we-offer-area.text-center .item {
  background: #252424 none repeat scroll 0 0;
  border: medium none;
  padding: 67px 40px 64px;
}

.we-offer-area.item-border-less .item {
  border: medium none;
}

.we-offer-area .our-offer-items.less-carousel .equal-height {
  margin-bottom: 30px;
}

.we-offer-area.item-border-less .item .number {
  font-family: "Poppins",sans-serif;
  font-size: 50px;
  font-weight: 900;
  opacity: 0.1;
  position: absolute;
  right: 30px;
  top: 30px;
}


/* .icon:hover{

    filter:drop-shadow(20px 50px 20px red) invert(75%);
} */


.we-offer-area .item h4 {
  font-weight: 600;
  text-transform: capitalize;
  color:black;
}

.we-offer-area .item p {
  margin: 0;
}

.we-offer-area .item i,
.we-offer-area .item h4,
.we-offer-area .item p {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}

